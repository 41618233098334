import Router from 'next/router';
import { contains } from 'ramda';
import { ofType } from 'redux-observable';
import { pathOr } from 'remeda';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { toggleCustomCostModal } from 'lib/budget/actions';
import { enquiryFormToggle } from 'lib/enquiries/actions';
import { cancelGuestEdit } from 'lib/guestlist/actions/guest-edit';
import { toggleAddGuestsPopup, toggleCategoryDetailsPopup } from 'lib/guestlist/actions/popups';
import { getIsAnyQuizOpen } from 'lib/quiz/selectors';
import { closeNotePopup } from 'lib/scrapbook/actions';
import { SearchActionTypes } from 'lib/search/action-types';
import { toggleCustomSupplierFormModal, toggleSupplierAddModal } from 'lib/shortlist/actions';
import {
  supplierVideoModalToggle,
  toggleReviewForm,
  toggleSupplierReviewsPage,
} from 'lib/supplier/actions';
import { SupplierActions } from 'lib/supplier/actions-types';
import { toggleAddCollaboratorModal } from 'lib/task/actions';
import { selectIsCollaboratorModalOpen } from 'lib/task/selectors';
import {
  Action,
  IApplicationState,
  IEpicDeps,
  IGuestlistState,
  IScrapbookState,
  ScrapbookNoteEditMode,
} from 'lib/types';
import { UrlHelper } from 'lib/url-helper';

export const closePopupOnAndroidBackEpic = (
  action$: Observable<Action>,
  { state$, warningModal }: IEpicDeps,
) =>
  action$.pipe(
    ofType('ANDROID_BACK_BUTTON_CLICKED'),
    withLatestFrom(state$),
    mergeMap(([, state]: [Action, IApplicationState]) => {
      const {
        supplier: { showGallery, reviewFormShow, videoModalOpened, showReviewsPage },
        app: { pathname },
      } = state;
      //Search
      const filtersShown = pathOr(state.search, ['filtersShown'], false);
      //Datepicker
      const datepickerModalOpened = pathOr(
        state.datepicker,
        ['instances', 'weddingDate', 'datepickerModalOpened'],
        false,
      );
      //Scrapbook
      // todo (adam) default value in here is not according to type, needs to be refactored
      const scrapbookModalMode = pathOr<IScrapbookState, 'mode'>(
        state.scrapbook,
        ['mode'],
        false as unknown as ScrapbookNoteEditMode,
      );
      //Checklist
      const showAssignTask = pathOr(state.checklist, ['showAssignTask'], false);
      //Budget
      const showCustomCostModal = pathOr(state.budget, ['showCustomCostModal'], false);
      //Enquiries
      const showEnquiryForm = pathOr(state.enquiries, ['showEnquiryForm'], false);
      //Shortlist
      const showCustomSupplierFormModal = pathOr(
        state.shortlist,
        ['showCustomSupplierFormModal'],
        false,
      );
      const addSupplierModalOpened = pathOr(state.shortlist, ['addSupplierModalOpened'], false);
      //Guestlist
      const addGuestsPopupVisible = pathOr(state.guestlist, ['addGuestsPopupVisible'], false);
      const categoryDetailsPopupVisible = pathOr<
        IGuestlistState,
        'categoryDetailsPopup',
        'isVisible'
      >(state.guestlist, ['categoryDetailsPopup', 'isVisible'], false);
      const guestlistEditGuestModal = pathOr(state.guestlist, ['edit'], false);

      const scrapbookModalOpened = contains(scrapbookModalMode, ['view', 'add', 'edit']);

      if (getIsAnyQuizOpen(state)) {
        return of();
      }

      if (selectIsCollaboratorModalOpen(state)) {
        return of(toggleAddCollaboratorModal(false));
      }

      if (
        pathname.includes(UrlHelper.inviteCollaborator) ||
        pathname.includes(UrlHelper.settings.manageCollaborators)
      ) {
        Router.back();
        return of();
      }

      if (showEnquiryForm) {
        return of(
          enquiryFormToggle({
            show: false,
            resetEnquiry: false,
            infoProps: { closeMethod: 'backButton' },
          }),
        );
      }

      if (showGallery) {
        return of({
          type: SupplierActions.SUPPLIER_GALLERY_TOGGLE,
          payload: false,
        });
      }

      if (addGuestsPopupVisible) {
        return of(toggleAddGuestsPopup(false));
      }

      if (showCustomSupplierFormModal) {
        return of(toggleCustomSupplierFormModal(false, false, 'backButton'));
      }
      if (addSupplierModalOpened && !showCustomSupplierFormModal) {
        return of(toggleSupplierAddModal(false));
      }

      if (showCustomCostModal) {
        return of(toggleCustomCostModal());
      }

      if (datepickerModalOpened) {
        return of({
          type: 'CANCEL_DATEPICKER_EDIT',
          payload: { datePickerId: 'weddingDate', track: true },
        });
      }

      if (categoryDetailsPopupVisible) {
        return of(toggleCategoryDetailsPopup(false));
      }

      if (showAssignTask) {
        return of({
          type: 'ASSIGN_TASK_TOGGLE',
          payload: false,
        });
      }

      if (scrapbookModalOpened) {
        return of(closeNotePopup());
      }

      if (guestlistEditGuestModal) {
        warningModal.toggleWarningModal();
        return [cancelGuestEdit()];
      }

      if (reviewFormShow) {
        return of(toggleReviewForm(false, 'backButton'));
      }

      if (videoModalOpened) {
        return of(supplierVideoModalToggle());
      }

      if (showReviewsPage) {
        return of(toggleSupplierReviewsPage(false));
      }

      if (filtersShown) {
        return of({
          type: SearchActionTypes.TOGGLE_FILTERS,
        });
      }

      if (window?.history?.back) {
        window.history.back();
      }

      return of();
    }),
    catchError((error) => of(appError({ error, feature: 'UI' }))),
  );
